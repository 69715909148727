( function ( document, window, index )
{
    var inputs = document.querySelectorAll( '.input-file' );
    Array.prototype.forEach.call( inputs, function( input )
    {
        var wrapper = input.parentElement,
            wrapperPlaceholder = wrapper.dataset.placeholder;

        input.addEventListener( 'change', function( e )
        {
            var fileName = '';
            if( this.files && this.files.length > 1 )
                fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
            else
                fileName = e.target.value.split( '\\' ).pop();

            console.log(fileName);

            if( fileName && wrapper.dataset.hasOwnProperty('placeholder') ) {
                wrapper.dataset.placeholder = fileName;
                wrapper.classList.add('selected');
            } else {
                wrapper.dataset.placeholder = wrapperPlaceholder;
            }
        });

        // Firefox bug fix
        input.addEventListener( 'focus', function(){ input.classList.add( 'has-focus' ); });
        input.addEventListener( 'blur', function(){ input.classList.remove( 'has-focus' ); });
    });
}( document, window, 0 ));

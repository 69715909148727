var beal = beal || {};

beal.rating = (function(){
    'use strict';

    var config = {
        selector: '[data-module="rating"]'
    };

    function init() {
        $(config.selector).each(function () {
            create($(this));
        });
    }

    function create($element) {
        $element.barrating({
            theme: 'fontawesome-stars-o',
            initialRating: 0
        });
    }

    return {
        init: init
    };

}());

var beal = beal || {};

beal.banner = (function(){
    'use strict';

    var config = {
        selector: '.banner-container',
        options: {
            pageDots: false,
            draggable: true,
            wrapAround: true,
            prevNextButtons: true,
            autoPlay: 5000
        },
        fkl: null
    };

    function init() {
        config.fkl = new Flickity(config.selector, config.options);
    }

    return {
        init: init
    }

}());

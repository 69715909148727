var beal = beal || {};

beal.stores = (function(){
    'use strict';

    var config = {
        visibleClass: 'visible',
        openBtn: '.be-open-store',
        selector: '[data-store]',
        pattern: '[data-store="{{slug}}"]'
    };

    function init() {
        $(config.openBtn).each(function () {
            create($(this));
        });
    }

    function create($element) {
        $element.on('click', function (e) {
            e.preventDefault();
            $('.be-store').removeClass('clicked');
            $(this).parents().eq(1).addClass('clicked');
            var slug = $element.attr('data-store-slug');
            $(config.selector).removeClass(config.visibleClass);
            var store = $(config.pattern.replace('{{slug}}', slug));
            store.addClass(config.visibleClass);
            beal.scrollTo(store);
        });
    }

    return {
        init: init
    }

}());

var beal = beal || {};

beal.mobile_menu = (function () {
    'use script';

    var config = {
        button: $('.be-mobile-menu-open'),
        menuClass: 'icon-menu',
        closeClass: 'icon-cancel',
        visibleClass: 'mobile-menu-opened'
    };

    function init() {
        config.button.on('click', function (e) {
            e.preventDefault();
            if($(this).find('.icon').hasClass(config.menuClass)) {
                $(this).find('.icon').removeClass(config.menuClass).addClass(config.closeClass);
            } else {
                $(this).find('.icon').removeClass(config.closeClass).addClass(config.menuClass);
            }
            $('html').toggleClass(config.visibleClass);
        });
    }

    return {
        init: init
    }

}());

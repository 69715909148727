var beal = beal || {};

beal.alert = (function(){
  'use strict';

  var config = {
    selector: '.message',
    closeBtn: '.close-message',
    autoClose: 8000
  };

  function init() {
    $(config.closeBtn).on('click', function (e) {
      e.preventDefault();
      close();
    });
    setTimeout(function () {
      close();
    }, config.autoClose);
  }

  function close() {
    $(config.selector).addClass('hide-message');
  }

  return {
    init: init
  }

}());

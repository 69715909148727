var beal = beal || {};

beal.products_categories = (function(){
    'use strict';

    var config = {
        products: $('[data-product-category]'),
        categoriesButton: '[data-category]',
        notVisibleClass: 'be-not-visible',
        visibleClass: 'be-is-visible'
    };

    function init() {
        $(config.categoriesButton).each(function () {
            create($(this));
        });
    }

    function create($element) {
        $element.click(function (e) {
            e.preventDefault();
            var category = $(this).attr('data-category');
            $(config.categoriesButton).removeClass('active');
            $(this).addClass('active');
            if(category == 'all') {
                config.products.removeClass(config.notVisibleClass).addClass(config.visibleClass);
            } else {
                config.products.removeClass(config.visibleClass);
                $('[data-product-category="'+category+'"]').removeClass(config.notVisibleClass).addClass(config.visibleClass);
                config.products.not('.' + config.visibleClass).addClass(config.notVisibleClass);
            }
        });
    }

    return {
        init: init
    }

}());

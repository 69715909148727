var beal = (function() {
    'use strict';

    function init() {
        loadModules();
    }

    function loadModules() {
        var modules = getModules();
        for (var i in modules) {
            if (modules.hasOwnProperty(i)) {
                beal[modules[i]].init();
                console.info("Module [" + modules[i] + "] initialized.");
                $.event.trigger(modules[i] + ':ready');
            }
        }
    }

    function isNullOrUndefined(value) {
        return (typeof value === 'undefined') || value === null;
    }

    // vendor prefixes
    function prefix() {
        var styles = window.getComputedStyle(document.documentElement, ''),
            pre = (Array.prototype.slice
                    .call(styles)
                    .join('')
                    .match(/-(moz|webkit|ms)-/) || (styles.OLink === '' && ['', 'o'])
            )[1],
            dom = ('WebKit|Moz|MS|O').match(new RegExp('(' + pre + ')', 'i'))[1];
        return {
            dom: dom,
            lowercase: pre,
            css: '-' + pre + '-',
            js: pre[0].toUpperCase() + pre.substr(1)
        };
    }

    function getModules() {
        var modules = [];
        $("[data-module]").each(function () {
            var module = $(this).data("module");
            if (modules.indexOf(module) === -1) {
                modules.push(module);
            }
        });
        return modules;
    }

    function isWindow( obj ) {
        return obj != null && obj === obj.window;
    }

    function getWindow( elem ) {
        return isWindow( elem ) ? elem : elem.nodeType === 9 && elem.defaultView;
    }

    function offset( elem ) {

        var docElem, win,
            box = { top: 0, left: 0 },
            doc = elem && elem.ownerDocument;

        docElem = doc.documentElement;

        if ( typeof elem.getBoundingClientRect !== typeof undefined ) {
            box = elem.getBoundingClientRect();
        }
        win = getWindow( doc );
        return {
            top: box.top + win.pageYOffset - docElem.clientTop,
            left: box.left + win.pageXOffset - docElem.clientLeft
        };
    };

    function scrollTo($element) {
        $('html, body').animate({
            scrollTop: $element.offset().top
        }, 700);
    }

    return {
        init: init,
        offset: offset,
        scrollTo: scrollTo,
        isNullOrUndefined: isNullOrUndefined,
        prefix: prefix
    }

}());
